import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { useCallback } from "react";
import {
  Button,
  SaveButton,
  SimpleForm,
  useNotify,
  Toolbar,
  useRefresh,
  SelectInput,
  required,
  FileInput,
  FileField,
} from "react-admin";
import { useParams } from "react-router-dom";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { AxiosError, uploadSellerFile } from "@/api";
import { getMD5Checksum } from "@/utils/md5-checksum";
import { getBase64 } from "@/utils/file-base64";
import { useSWRConfig } from "swr";

const DocumentModalOpen = atom(false);
const isLoading = atom(false);

export const AddDocumment = () => {
  const setDocumentModalOpen = useSetAtom(DocumentModalOpen);

  return (
    <div>
      <Tooltip title="Adjuntar documento">
        <div>
          <IconButton
            color="primary"
            onClick={() => setDocumentModalOpen(true)}
          >
            <FilePresentIcon />
          </IconButton>
        </div>
      </Tooltip>
      <DocumentDialog />
    </div>
  );
};

export const DocumentDialog = () => {
  const [open, setOpen] = useAtom(DocumentModalOpen);
  const setLoading = useSetAtom(isLoading);
  const { id: sellerId = "" } = useParams();
  const refresh = useRefresh();
  const notify = useNotify();
  const { mutate } = useSWRConfig();

  const onSubmit = useCallback(
    async (values: any) => {
      let { documentImage, fileType } = values;

      if (!documentImage?.rawFile) {
        notify("Agrega al menos un documento", { type: "error" });
        return;
      }

      setLoading(true);

      try {
        const base64 = await getBase64(documentImage.rawFile);
        const ext = base64.split(";")[0].split("/")[1];
        const md5 = await getMD5Checksum(documentImage.rawFile);

        await uploadSellerFile({
          sellerId: sellerId,
          fileName: md5 + "." + ext,
          file: base64,
          fileType,
        });

        notify("El documento se envió exitosamente", { type: "success" });
        setOpen(false);
        refresh();
        mutate(() => [sellerId, "seller-documents"]);
      } catch (error) {
        console.log({ error });
        if (error instanceof AxiosError) {
          notify(error.response?.data?.message ?? error.message, {
            type: "error",
          });
        } else if (error instanceof Error) {
          notify(error.message ?? "No se pudo cargar el documento", {
            type: "error",
          });
        }
      }
      setLoading(false);
    },
    [sellerId]
  );

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>Agregar documento</DialogTitle>
      <DialogContent>
        <SimpleForm
          mode="onBlur"
          record={undefined}
          onSubmit={onSubmit}
          toolbar={<DialogToolbar />}
        >
          <FileInput
            source="documentImage"
            label="Archivo del documento (.jpg, .png, .pdf)"
            accept="image/png, image/jpg, image/jpeg, application/pdf"
            validate={[
              required(),
              (value) => {
                if (value?.rawFile.size > 1_500_000)
                  return "El archivo supera los 1.5MB";
              },
            ]}
            helperText="Archivo de menos de 1.5MB. Si el archivo no carga es porque supera el peso de 1.5MB."
          >
            <FileField source="src" title="title" />
          </FileInput>
          <SelectInput
            source="fileType"
            label="Tipo de documento"
            choices={[
              "Pagaré",
              "Documento de identidad",
              "Habeas data firmado",
              "Acuerdo de pago",
              "Otro",
            ].map((id) => ({ id, name: id }))}
            validate={[required()]}
            fullWidth
          />
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        <Button label="Cerrar" onClick={() => setOpen(false)} />
      </DialogActions>
    </Dialog>
  );
};

export const DialogToolbar = () => {
  const disabled = useAtomValue(isLoading);
  return (
    <Toolbar>
      <SaveButton disabled={disabled} />
    </Toolbar>
  );
};
