import type { FC } from "react";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  email,
  maxLength,
  minLength,
  number,
  ReferenceInput,
  regex,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useRecordContext,
} from "react-admin";
import { ADMINROLES } from "../../config/resources";
import { useAdmin } from "../../hooks/admin";
import { Grid } from "@mui/material";
import { permissions } from "../permissions/data";
import { availableEmailOrPhone } from "../shared/available-email-or-phone";
import type { TAdmin } from "./types";
import { countries, phonePrefixChoices } from "@/config/country";

const AdminEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const AdminForm: FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  const { hasPermission } = useAdmin();
  const currentAdmin = useRecordContext<TAdmin>();

  return (
    <SimpleForm
      mode="onBlur"
      toolbar={<AdminEditToolbar />}
      warnWhenUnsavedChanges
      defaultValues={{
        phonePrefix: "",
        phone: "",
      }}
    >
      <Grid container spacing={4}>
        {isEdit && (
          <Grid item xs={4}>
            <TextInput source="id" disabled fullWidth />
          </Grid>
        )}
        <Grid item xs={4}>
          <TextInput
            source="firstName"
            validate={required()}
            disabled={!hasPermission("admin.update.name") && isEdit}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput source="lastName" validate={required()} fullWidth />
        </Grid>
        <Grid item xs={4}>
          <ReferenceInput
            source="roleId"
            reference={ADMINROLES}
            sort={{ field: "name", order: "ASC" }}
            perPage={100}
          >
            <AutocompleteInput
              label="Rol"
              optionText="name"
              validate={required()}
              filterToQuery={(searchText: string) => ({ name: searchText })}
              disabled={!hasPermission("admin.update.role") && isEdit}
              fullWidth
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={4}>
          <TextInput
            source="email"
            validate={[
              required(),
              email(),
              regex(
                /.*@(seeri|brm\.com)\.co$/,
                "El correo no es de Seeri o BRM"
              ),
              // Email already exists validation
              availableEmailOrPhone("email", currentAdmin),
            ]}
            parse={(v: string) => v?.toLowerCase()}
            disabled={isEdit}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            source="phonePrefix"
            choices={phonePrefixChoices}
            validate={isEdit ? [] : required()}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            source="phone"
            validate={[
              ...(isEdit ? [] : [required()]),
              number(),
              minLength(10),
              maxLength(10),
              (value, values) => {
                if (value && !values.phonePrefix) {
                  return "Elije el código de país antes";
                }
              },
              // Phone already exists validation
              availableEmailOrPhone("phone", currentAdmin),
            ]}
            disabled={isEdit}
            inputMode="tel"
            fullWidth
          />
        </Grid>
        {((hasPermission("admin.update.country") && isEdit) || !isEdit) && (
          <Grid item xs={4}>
            <AutocompleteArrayInput
              label="País(es)"
              source="countries"
              choices={countries}
              optionValue="code"
              validate={required()}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={4}>
          <BooleanInput
            source="status"
            defaultValue={true}
            disabled={!hasPermission("admin.update.status") && isEdit}
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteArrayInput
            source="permissions"
            choices={permissions.map((p) => ({ id: p.id, name: p.name }))}
            optionText="name"
            defaultValue={[]}
            fullWidth
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
