import { isProd } from "../constants/env";

const bucketsDev = {
  purchaseReturn: "seeri-dev-temp",
  purchases: "seeri-dev-purchases",
  education: "seeri-dev-education",
  banners: "seeri-dev-banners",
  products: "seeri-dev-products",
  quotes: "seeri-dev-quotes",
};

const bucketsProd = {
  purchaseReturn: "seeri-dev-temp",
  purchases: "seeri-purchases",
  education: "seeri-education",
  banners: "seeri-banners",
  products: "seeri-products",
  quotes: "seeri-quotes",
};

export const buckets = isProd ? bucketsProd : bucketsDev;
